import React, { useContext, useState } from 'react';
import { Button, Divider, Dialog, Box, Stack, Tab, Tabs, IconButton, DialogContent, DialogTitle, Container } from '@mui/material';
import TemplateTable from '../browse/components/templateTable';
import BatchTable from '../browse/components/batchTable';
import PagedTableWrapper from '../browse/components/pagedTableWrapper';
import QueryTemplateBuilder from '../templateBuilder/queryTemplateBuilder';
import BatchBuilder from '../batchBuilder/batchBuilder';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import UserContext from '../userContext';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import { PATHS } from '../constants';

const TemplateViewTab = (props) => {
  const { tab, setTab } = props;
  const style = { textTransform: 'none', fontSize: '1.5rem', fontWeight: 400, mt: 0 };
  const history = useHistory();
  const { search } = useLocation();
  return (
    <Tabs
      value={tab}
      onChange={(e, v) => {
        const params = new URLSearchParams(search);
        params.delete('id');
        history.push({
          pathname: v === 0 ? PATHS.search : PATHS.searchBatch,
          search: params.toString(),
        });
        setTab(v);
      }}
    >
      <Tab label="Templates" sx={{ ...style }} />
      <Tab label="Batches" sx={{ ...style }} />
    </Tabs>
  );
};

const SelectSearch = (props) => {
  const { selectedSearch, setSelectedSearch, nextStep } = props;
  const [builderOpen, setBuilderOpen] = useState(false);
  const [tab, setTab] = useState(useLocation().pathname === PATHS.searchBatch ? 1 : 0);
  const { user } = useContext(UserContext);
  const [refreshTableFlag, setRefreshTableFlag] = useState();

  return (
    <Container maxWidth="xl">
      <Stack direction="row" spacing={2} sx={{justifyContent:"space-between"}}>
        <TemplateViewTab tab={tab} setTab={setTab} />
        <Box className="center">
          <Button className="center" variant="contained" onClick={() => setBuilderOpen(true)} startIcon={<AddIcon />}>
            {`New ${tab === 0 ? 'Template' : 'Batch'}`}
          </Button>
        </Box>
      </Stack>
      <Divider sx={{ mt: 0, mb: 2 }} />
      {tab === 0 && (
        <PagedTableWrapper
          title=""
          queryBase="template/"
          defaultOrdering="-created_at"
          syncUrl={true}
          filters={['author', 'showArchived', 'id', user.is_admin ? 'showAll' : undefined, 'favorites']}
          useSearchBar={true}
          TableComponent={TemplateTable}
          refresh={refreshTableFlag}
          tableProps={{
            enableSelection: false,
            multiSelect: false,
            selectedItems: selectedSearch,
            setSelectedItems: setSelectedSearch,
            enableSearch: true,
            searchAction: (item) => {
              setSelectedSearch([item]);
              nextStep();
            },
          }}
        />
      )}
      {tab === 1 && (
        <PagedTableWrapper
          title=""
          queryBase="batch/"
          defaultOrdering="-created_at"
          syncUrl={true}
          filters={['author', 'showArchived', 'id', user.is_admin ? 'showAll' : undefined, 'favorites']}
          useSearchBar={true}
          TableComponent={BatchTable}
          refresh={refreshTableFlag}
          tableProps={{
            enableSelection: false,
            multiSelect: false,
            selectedItems: selectedSearch,
            setSelectedItems: setSelectedSearch,
            enableSearch: true,
            searchAction: (item) => {
              setSelectedSearch([item]);
              nextStep();
            },
          }}
        />
      )}

      <Dialog maxWidth="xl" fullWidth open={builderOpen} onClose={() => setBuilderOpen(false)} sx={{ p: 2 }}>
        <DialogTitle>
          {tab === 0 ? 'Template ' : 'Batch '} Builder
          <IconButton
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
            onClick={() => setBuilderOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {tab === 0 && (
            <QueryTemplateBuilder
              onSave={() => {
                setBuilderOpen(false);
                setRefreshTableFlag(Date.now);
              }}
            />
          )}
          {tab === 1 && (
            <BatchBuilder
              onSave={() => {
                setBuilderOpen(false);
                setRefreshTableFlag(Date.now);
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default SelectSearch;
