import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Link,
  Select,
  Paper,
  MenuItem,
  InputLabel,
  Typography,
  TextField,
  Stack,
  Chip,
} from '@mui/material/';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FIELDS_FOR_COMPLETE_COUNT } from './piiForm';
import dayjs from 'dayjs';

function linkLength(links, newLink = "") {
  let count = links.reduce((c, link) => c+= link.length, links.length)
  count += newLink.length
  return count
}

// returns an object used to populate the form questions and dropdown boxes for PII form
const FORM_OPTIONS = {
  dataTypeSearched: {
    question: 'Data Type Searched',
    choices: [
      { label: 'Open Web', value: 'Open Web' },
      { label: 'Social Media', value: 'Social Media' },
      { label: 'Deep Dark Web', value: 'Deep Dark Web' },
      { label: 'Public Records', value: 'Public Records' },
      { label: 'Data Broker', value: 'Data Broker' },
    ],
  },
  dataTypeFound: {
    question: 'Data Type Found',
    choices: [
      { label: 'Open Web', value: 'Open Web' },
      { label: 'Social Media', value: 'Social Media' },
      { label: 'Deep Dark Web', value: 'Deep Dark Web' },
      { label: 'Public Records', value: 'Public Records' },
      { label: 'Data Broker', value: 'Data Broker' },
    ],
  },
  searchMethod: {
    question: 'Search Method',
    choices: [
      { label: 'QA Tool', value: 'QA Tool' },
      { label: 'Google', value: 'Google' },
      { label: 'Yahoo', value: 'Yahoo' },
      { label: 'Bing', value: 'Bing' },
    ],
  },
  piiAttributesFound: {
    question: 'PII Attribute(s) Found',
    choices: [
      { label: 'Current/Primary Home Address', value: 'Home_Address', bind: 'fullPartialAddress', options: ['Full', 'Moderate', 'Low'] },
      {
        label: 'Previous/Secondary Home Address',
        value: 'Home_Address_Secondary',
        bind: 'fullPartialAddress',
        options: ['Full', 'Moderate', 'Low'],
      },
      { label: 'SSN', value: 'SSN', bind: 'fullPartialSSN', options: ['Full', 'Moderate'] },
      { label: "Driver's License Number", value: 'Drivers_License_Number' },
      { label: 'License Plate # or other unique identifiers of a vehicle owned/leased/regularly used', value: 'License_Plate_VIN' },
      { label: 'Identification of children or an at-risk individual under the age of 18', value: 'Children' },
      { label: 'Home or personal mobile telephone number', value: 'Phone' },
      { label: 'Bank account/credit/debit card information', value: 'Bank_Credit_Debit_info' },
      { label: 'DoB', value: 'DOB', bind: 'fullPartialDob', options: ['Full', 'Moderate', 'Low'] },
      { label: 'Personal Email', value: 'Personal_Email' },
      {
        label: 'Employment information (other than government) to include name, location/address, schedules, routes to/from',
        value: 'Employment_Information',
      },
      {
        label: 'Current or future school or day care attendance including name, address, schedules of attendance, routes taken to/from',
        value: 'School_Info',
        bind: 'fullPartialSchoolInfo',
        options: ['Full', 'Moderate', 'Low'],
      },
    ],
  },
  daaExempt: {
    question: 'Is this DAA Exempt Activity',
    choices: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ],
  },
  daaExemptType: {
    question: 'Type of DAA Exempted Activity',
    choices: [
      { label: 'Reporting/newsgathering/matters of public interest', value: 'reporting_new_pub_interest' },
      { label: '411 directory assistance on behalf of or as a function of a telecommunications carrier', value: '411_directory_assist' },
      { label: 'Personal use of the information ', value: 'personal_use' },
      {
        label: 'Publicly available information via real-time alert services for health and safety purposes',
        value: 'pub_avail_health_safety',
      },
      { label: 'Consumer reporting subject to Fair Credit Reporting Act', value: 'consumer_reporting' },
      { label: 'Social Media Terms of Service Violations Request', value: 'social_media_violation' },
      { label: 'Website Opt-Out Processes', value: 'website_opt_out' },
      { label: 'Public Records Agency Process (Based on local/state legislation)', value: 'public_records_process' },
    ],
  },
};

const SimpleDropdown = (props) => {
  const { field, rowData, onPIIFormUpdate, disabled } = props;
  const formQuestion = FORM_OPTIONS[field];
  return (
    <FormControl size="small">
      <InputLabel id={field + '-label'}>{formQuestion.question}</InputLabel>
      <Select
        id={field}
        labelId={field + '-label'}
        label={formQuestion.question}
        value={rowData.pii_form[field]}
        onChange={(e) => onPIIFormUpdate(field, e.target.value)}
        disabled={disabled}
        size="small"
      >
        {formQuestion.choices.map((choice, index) => {
          return (
            <MenuItem key={index} value={choice.value}>
              {choice.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

/**
 * Renders a dialog allowing the user to record all PII found and answer other questions for vulnerability tool
 */
const PIIFormModal = (props) => {
  const { open, onClose, row, setRowResult } = props;
  const [rowData, setRowData] = useState(null);
  const [newLink, setNewLink] = useState('');
  const [linkError, setLinkError] = useState(false);


  useEffect(() => {
    if (open && rowData === null) {
      setRowData(structuredClone(row));
    }
    if (!open && rowData !== null) {
      setRowData(null);
    }
  }, [open, row, rowData, setRowData]);

  // input change for a field in row.pii_form
  const onPIIFormUpdate = (field, value) => {
    let newRow = Object.assign({}, rowData);
    newRow.pii_form[field] = value;
    setRowData(newRow);
  };

  const onPIIAttributeUpdate = (checked, value, bind) => {
    let newRow = Object.assign({}, rowData);
    let attributes = newRow.pii_form.piiAttributesFound.split(',');
    let cleanedValues = attributes.filter((attribute) => attribute !== value && attribute !== '');
    if (checked) {
      cleanedValues.push(value);
      if (bind && rowData.pii_form[bind] === '') {
        let choice = FORM_OPTIONS.piiAttributesFound.choices.find((choice) => choice.value === value);
        rowData.pii_form[bind] = choice.options[0];
      }
    } else if (bind) {
      rowData.pii_form[bind] = '';
    }
    newRow.pii_form.piiAttributesFound = cleanedValues.join();
    setRowData(newRow);
  };

  const handleInsertLink = () => {
    //count length of strings, don't allow it to be longer than 2000 characters
    let count = linkLength(rowData.extra_links, newLink)
    if (count <= 2000){
      let newRow = Object.assign({}, rowData);
      newRow.extra_links.push(newLink)
      setRowData(newRow)
      setNewLink('')
    }
    else{
      setLinkError(true)
    }
  }

  const constantKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleInsertLink();
    }
  };

  const handleLinkDelete = (e, index) => {
    e.preventDefault();
    let newRow = Object.assign({}, rowData);
    newRow.extra_links.splice(index,1);
    setRowData(newRow)
    let count = linkLength(rowData.extra_links)
    if (count < 2000){
      setLinkError(false)
    }
  }

  const saveChanges = () => {
    let fields_filled = 0;
    FIELDS_FOR_COMPLETE_COUNT.forEach((field) => {
      if (rowData.pii_form[field] !== '') {
        fields_filled += 1;
      }
    });
    rowData.form_completion = fields_filled;
    setRowResult(rowData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>
        PII Details Form
        <Typography className="blockquote">
          <span className="bold">{rowData?.title || ''}</span>
          <br />
          <Link href={rowData?.link || ''} target="_blank">
            {rowData?.link || ''}
          </Link>
        </Typography>
      </DialogTitle>
      {rowData && rowData.pii_form && (
        <DialogContent>
          <Stack spacing={4} sx={{ mt: '10px' }}>
            {rowData.extra_links.length > 0 && (
              <Stack direction="row" sx={{ flexWrap: 'wrap', gap: '10px' }}>
                {rowData.extra_links.map((link, index) => {
                  return (
                    <Chip
                      color="primary"
                      key={index}
                      label={link}
                      component="a"
                      href={link}
                      target="_blank"
                      clickable
                      onDelete={(e) => {
                        handleLinkDelete(e, index);
                      }}
                    />
                  );
                })}
              </Stack>
            )}
            <TextField
              variant="outlined"
              fullWidth
              value={newLink}
              label="Additional Links"
              disabled={linkError}
              error={linkError}
              helperText={linkError ? 'The new link would make size of Additional Links too large, try removing other links.' : ''}
              onKeyDown={constantKeyPress}
              onChange={(event) => {
                setNewLink(event.target.value);
              }}
              size="small"
            />
            <Box className="auto-column-450">
              <SimpleDropdown field="dataTypeSearched" rowData={rowData} onPIIFormUpdate={onPIIFormUpdate} disabled={true} />
              <SimpleDropdown field="dataTypeFound" rowData={rowData} onPIIFormUpdate={onPIIFormUpdate} disabled={false} />
              <SimpleDropdown field="searchMethod" rowData={rowData} onPIIFormUpdate={onPIIFormUpdate} disabled={true} />
              <SimpleDropdown field="daaExempt" rowData={rowData} onPIIFormUpdate={onPIIFormUpdate} disabled={false} />
              <SimpleDropdown
                field="daaExemptType"
                rowData={rowData}
                onPIIFormUpdate={onPIIFormUpdate}
                disabled={rowData.pii_form.daaExempt !== 'Yes'}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slotProps={{
                    textField: {
                      error: false,
                      size: 'small',
                    },
                  }}
                  label="Date of Result"
                  value={dayjs(rowData.pii_form.dateOfResult)}
                  onChange={(e) => onPIIFormUpdate('dateOfResult', e.$d)}
                />
              </LocalizationProvider>
            </Box>

            <TextField
              id="summaryOfInformation"
              label="Summary of Information Found"
              multiline
              minRows={2}
              maxRows={4}
              value={rowData.pii_form.summaryOfInformation}
              error={rowData.pii_form.summaryOfInformation.length === 2000 ? true : false}
              helperText={rowData.pii_form.summaryOfInformation.length === 2000 ? "Character Limit Reached" : ""}
              onChange={(e) => onPIIFormUpdate('summaryOfInformation', e.target.value)}
              size="small"
              inputProps={{maxLength: 2000}}
            />
            <Paper sx={{p:2, bgcolor: '#f8f8f8'}}>
              <Typography className="bold" sx={{mb: 1}}>Attributes Found</Typography>
              <Box className="auto-column-300">
                {FORM_OPTIONS.piiAttributesFound.choices.map((attribute, index) => {
                  let match = rowData.pii_form.piiAttributesFound.split(',').find((e) => e === attribute.value);
                  const exists = match !== undefined;
                  return (
                    <Paper key={index} sx={{ bgcolor: exists ? 'aliceblue' : 'white' }}>
                      <Box sx={{ m: 2 }}>
                        <Typography variant="body2" className={exists ? 'bold' : ''}>
                          {attribute.label}
                        </Typography>
                      </Box>
                      <Stack direction="row" sx={{ m: 2 }}>
                        <Checkbox checked={exists} onChange={() => onPIIAttributeUpdate(!exists, attribute.value, attribute.bind)} />
                        <FormControl fullWidth size="small">
                          {attribute.bind ? (
                            <Select
                              id={attribute.value}
                              value={rowData.pii_form[attribute.bind]}
                              onChange={(e) => onPIIFormUpdate(attribute.bind, e.target.value)}
                              disabled={!exists}
                              size="small"
                              displayEmpty
                            >
                              <MenuItem value="" sx={{ display: exists ? 'none' : 'inherit' }}>
                                Not Found
                              </MenuItem>
                              {attribute.options.map((option, index) => {
                                return (
                                  <MenuItem key={index} value={option}>
                                    {option}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          ) : (
                            <Select id={attribute.value} value={exists} disabled={true} size="small">
                              <MenuItem value={true}>Found</MenuItem>
                              <MenuItem value={false}>Not Found</MenuItem>
                            </Select>
                          )}
                        </FormControl>
                      </Stack>
                    </Paper>
                  );
                })}
              </Box>
            </Paper>
            <TextField
              id="endNotes"
              label="End Notes"
              multiline
              minRows={2}
              maxRows={4}
              value={rowData.pii_form.endNotes}
              error={rowData.pii_form.endNotes.length === 2000 ? true : false}
              helperText={rowData.pii_form.endNotes.length === 2000 ? 'Character Limit Reached' : ""}
              onChange={(e) => onPIIFormUpdate('endNotes', e.target.value)}
              size="small"
              inputProps={{maxLength: 2000}}
            />
          </Stack>
        </DialogContent>
      )}
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Discard Changes
        </Button>
        <Button variant="contained" onClick={saveChanges}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PIIFormModal;
