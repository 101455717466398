import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { confirmable, createConfirmation } from 'react-confirm';

/**
 * Renders a dialog allowing the user to then confirm in creating a new search removing existing results.
 * Uses react-confirm to make the dialog callable.
 * @param {bool} show: Pass as true to show the confirmNewSearch dialog
 * @param {Function} proceed: Not passed in props directly, used to resolve the promise
 * @param {Function} dismiss: Not passed in props directly, closes the dialog
 * @param {Function} cancel: Not passed in props directly, used to cancel the promise
 * @returns
 */
const confirmNewSearch = (props) => {
  const { show, proceed, dismiss } = props;

  return (
    <div>
      <Dialog open={show} onClose={dismiss}>
        <DialogTitle>Start a New Search</DialogTitle>
        <DialogContent>
          Starting a new search will clear the current results.<br/>
          <Typography color="error" className="bold">Make sure you have exported anything you want to keep!</Typography>
          </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={() => dismiss()}>Cancel</Button>
          <Button color="primary" variant="outlined" onClick={() => proceed(true)}>New Search</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const confirmedNewSearch = createConfirmation(confirmable(confirmNewSearch));
