import React, { useEffect, useState, useContext } from 'react';
import { Box, Divider, Typography, Button, Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import moment from 'moment';
import ResultTable from './components/resultTable';
import { getPIIFormTemplate } from './components/piiForm';
import PIIFormModal from './components/piiFormModal';
import ExportResultsDialog from './components/exportResultsDialog';
import { useSnackbar } from 'notistack';
import UserContext from '../userContext';
import FileSaver from 'file-saver';
import Papa from 'papaparse';
import { FileDownload, RestartAlt } from '@mui/icons-material';
import { confirmedNewSearch } from './components/newSearchConfirmation';

/**
 * Renders the list of results present in the 'user.searchResponse' localStorage space
 * @returns {React.ReactNode} An interactive results list
 */
const SearchResults = (props) => {
  const { results, setResults, resetSearchStep } = props;
  const currentDateTime = moment().format('YYYYMMDDHHmmss');
  const { closeSnackbar } = useSnackbar();
  const [piiRow, setPiiRow] = useState(null);
  const [openPIIModal, setOpenPIIModal] = useState(false);
  const [openExportResultsDialog, setOpenExportResultsDialog] = useState(false);
  const [exportSettings, setExportSettings] = useState({
    flaggedOnly: true,
    format: 'csv',
    columns: [
      { field: 'id', header: 'Index', export: true, quote: false },
      { field: 'rank', header: 'Rank', export: true, quote: false },
      { field: 'emphasis', header: 'Emphasis', export: true, quote: false },
      { field: 'contains_pii', header: 'Contains PII', export: true, quote: false },
      { field: 'title', header: 'Title', export: true, quote: true },
      { field: 'link', header: 'Link', export: true, quote: true },
      { field: 'snippet', header: 'Snippet', export: true, quote: true },
      { field: 'search_query', header: 'Query', export: true, quote: true },
      { field: 'template_name', header: 'Template', export: true, quote: true },
      { field: 'plugin_tag', header: 'Plugin', export: true, quote: true },
      { field: 'extra_links', header: 'Extra Links', export: true, quote: true },
      ...Object.keys(getPIIFormTemplate()).map((key) => {
        return {
          field: key,
          header: key,
          export: true,
          quote: true,
        };
      }),
    ],
  });
  const { user, setUser } = useContext(UserContext);

  // when resultSets changes, redo the results
  useEffect(() => {
    if (user.searchResponse.reRender === true) {
      const rowData = [];
      user.searchResponse.results.forEach((result) => {
        const meta = result.meta_keys.map((key) => user.searchResponse.meta_search[key]).reverse();
        rowData.push({
          id: result.id,
          rank: result.rank,
          template_name: meta.map(({ template }) => template.name),
          plugin_tag: meta.map(({ plugin_info }) => plugin_info.name),
          title: result.title,
          link: result.link,
          extra_links: [],
          snippet: result.snippet,
          search_query: meta.map(({ query_string }) => query_string),
          emphasis: result.emphasis,
          contains_pii: false,
          pii_form: getPIIFormTemplate(),
          form_completion: 0 // utility not for export
        });
      });
      setResults(rowData);
      setUser({
        ...user,
        searchResponse: { ...user.searchResponse, reRender: false },
      });
    }
  }, [user, setUser, setResults]);

  const handleClickClear = () => {
    confirmedNewSearch({ show: true }).then(
      () => {
        setUser({ ...user, searchResponse: {} });
        resetSearchStep();
      },
      () => {
        console.log('cancelled');
      }
    );
  };

  useEffect(() => {
    // On page load close the search results snackbar
    if (user.snackId !== null) {
      closeSnackbar(user.snackId);
    }
  });

  const createXML = (exportData) => {
    var doc = document.implementation.createDocument('', '', null);
    var results = doc.createElement('results');
    for (const item of exportData) {
      var result = doc.createElement('result');
      result.setAttribute('Index', item.id);
      result.setAttribute('Rank', item.rank);
      result.setAttribute('Emphasis', item.emphasis);
      result.setAttribute('ContainsPII', item.contains_pii);
      result.setAttribute('Title', item.title);
      result.setAttribute('Link', item.link);
      result.setAttribute('Snippet', item.snippet);
      result.setAttribute('Template', item.template_name.join('\r\n') || item.template_name); //insert new line after each template name and plugin name
      result.setAttribute('Plugin', item.plugin_tag.join('\r\n') || item.plugin_tag);
      results.appendChild(result);
    }
    doc.appendChild(results);
    return doc;
  };

  const handleExport = () => {
    // array of fields to hide
    const hiddenFields = exportSettings.columns.filter((col) => !col.export).map((col) => col.field);
    // filter the results for exporting
    const exportData = results
      .filter((row) => !exportSettings.flaggedOnly || row.contains_pii === true)
      .map((row) => {
        let newRow = {};
        Object.keys(row).forEach((key) => {
          if (!hiddenFields.includes(key)) {
            newRow[key] = row[key]
          }
        });
        Object.keys(row.pii_form).forEach((key) => {
          if (!hiddenFields.includes(key)) {
            newRow[key] = row.pii_form[key]
          }
        });
        return newRow;
      });
    switch (exportSettings.format) {
      case 'csv':
        // adding keyed header row for prettier names
        const headerRow = {};
        exportSettings.columns.filter((col) => col.export === true).forEach((col) => (headerRow[col.field] = col.header));
        exportData.splice(0, 0, headerRow);
        // filter and order columns by keys, use the header row above instead of auto header
        let csvstr = Papa.unparse(exportData, {
          header: false,
          escapeFormulae: true,
          quotes: exportSettings.columns.filter((col) => col.export === true).map((col) => col.quote),
          columns: exportSettings.columns.filter((col) => col.export === true).map((col) => col.field),
        });
        // create binary blob and save
        const csvblob = new Blob([`${csvstr}`], {
          type: 'text/csv;charset=utf-8',
        });
        FileSaver.saveAs(csvblob, `batch_${currentDateTime}.csv`);
        break;

      case 'json':
        // create binary blob and save
        const jsonblob = new Blob([`${JSON.stringify(exportData, null, 2)}`], {
          type: 'text/json;charset=utf-8',
        });
        FileSaver.saveAs(jsonblob, `batch_${currentDateTime}.json`);
        break;

      case 'xml':
        const xmlDoc = createXML(exportData);
        const xmlString = new XMLSerializer().serializeToString(xmlDoc.documentElement);
        var xmlblob = new Blob([xmlString], { type: 'text/XMLDocument' });
        FileSaver.saveAs(xmlblob, `batch_${currentDateTime}.xml`);
        break;

      default:
        console.error('Unknown export type');
        break;
    }
  };

  const openEditRowPII = (row) => {
    setPiiRow(row);
    setOpenPIIModal(true);
  };

  const setRowResult = (updatedRow) => {
    setResults(results.map((row) => (updatedRow.id === row.id ? updatedRow : row)));
  };

  return (
    <Container maxWidth="false">
      <Grid container spacing={3}>
        <Grid xs={7}>
          <Typography variant="h5" color="primary">
            Search Results
          </Typography>
        </Grid>
        <Grid xs={5} display="flex" justifyContent="right">
          <Box sx={{ m: 1 }} />
          <Button
            variant="contained"
            onClick={() => setOpenExportResultsDialog(true)}
            sx={{ float: 'right' }}
            disabled={user.searchResponse.results === undefined}
            startIcon={<FileDownload />}
          >
            Export
          </Button>
          <Box sx={{ m: 1 }} />
          <Button
            variant="contained"
            color="success"
            onClick={handleClickClear}
            disabled={user.searchResponse.results === undefined}
            startIcon={<RestartAlt />}
          >
            New Search
          </Button>
        </Grid>
        <Grid xs={12} sx={{ paddingTop: '0px' }}>
          <Divider />
        </Grid>
        {user.searchResponse.results ? (
          <ResultTable
            results={results}
            setResults={setResults}
            exportSettings={exportSettings}
            setExportSettings={setExportSettings}
            openEditRowPII={openEditRowPII}
          />
        ) : (
          <Typography>Perform a search request in the Batch Builder page to view results.</Typography>
        )}
      </Grid>
      <ExportResultsDialog
        open={openExportResultsDialog}
        onClose={() => {
          setOpenExportResultsDialog(false);
        }}
        handleExport={handleExport}
        exportSettings={exportSettings}
        setExportSettings={setExportSettings}
      />
      <PIIFormModal
        open={openPIIModal}
        onClose={() => {
          setOpenPIIModal(false);
        }}
        row={piiRow}
        setRowResult={setRowResult}
      />
    </Container>
  );
};

export default SearchResults;
