// returns the object with actual user input values to export with search results
export const getPIIFormTemplate = () => {
  return {
    dataTypeSearched: 'Open Web', // Only if the link is an extra link this will change to "Data Broker"
    dataTypeFound: '', // question index 0 value
    searchMethod: 'QA Tool', // 
    dateOfResult: '', // iso date string
    summaryOfInformation: '', // user input notes
    piiAttributesFound: '', // comma separated string of question index 1 values (multi-select)
    fullPartialAddress: '', // question index 2 value
    fullPartialSSN: '', // question index 2 value (cannot choose Low)
    fullPartialDob: '', // question index 2 value
    fullPartialSchoolInfo: '', // question index 2 value
    daaExempt: 'No', // question index 3 value
    daaExemptType: '', // question index 4 value
    endNotes: '', // additional notes field
  };
};

export const FIELDS_FOR_COMPLETE_COUNT = ['dataTypeFound', 'dateOfResult', 'summaryOfInformation', 'piiAttributesFound', 'endNotes'];