import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import {
  Stack,
  Container,
  Typography,
  Button,
  Link,
  Tooltip,
  IconButton,
  Popper,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { Check, Clear, Delete, EditNote } from '@mui/icons-material';
import Flag from '@mui/icons-material/Flag';
import FlagOutlined from '@mui/icons-material/FlagOutlined';
import { DataGrid, useGridApiContext, GridCellEditStopReasons } from '@mui/x-data-grid';
import GPSE_icon from '../../images/search_engines/chrome_icon.svg';
import test_icon from '../../images/search_engines/test_icon.png';
import google_icon from '../../images/search_engines/google_icon.svg';
import yahoo_icon from '../../images/search_engines/yahoo_icon.svg';
import bing_icon from '../../images/search_engines/bing_icon.png';
import PriorityHighIcon from '@mui/icons-material/PriorityHighRounded';
import { FIELDS_FOR_COMPLETE_COUNT, getPIIFormTemplate } from './piiForm';

const ResultTable = (props) => {
  const { results, setResults, openEditRowPII } = props;
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  // helper function to set the contains_pii variable for a result
  const flag_pii = (row_id) => {
    setResults(results.map((row) => (row_id === row.id ? { ...row, contains_pii: !row.contains_pii } : row)));
  };

  // helper function to convert arrays of strings to a single comma seperated string
  const array_to_string = (items) => {
    var formatted = '';
    for (var item of items) {
      if (formatted.includes(item)) {
        continue;
      } else if (formatted !== '') {
        formatted = formatted + ', ' + item;
      } else {
        formatted += item;
      }
    }
    return formatted;
  };

  // helper function for Note Edit Element
  function isKeyboardEvent(event) {
    return !!event.key;
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
      align: 'left',
      display: 'flex',
    },
    {
      field: 'rank',
      headerName: 'Rank',
      minWidth: 125,
      align: 'center',
      display: 'flex',
      renderCell: (params) => {
        return (
          <Stack direction="row" alignItems="center">
            <Typography variant="body2" textAlign="center" display="flex">
              {params.row.rank}
              {params.row.emphasis && (
                <Tooltip title="This url appears in the emphasis list">
                  <PriorityHighIcon fontSize="small" className="material-icons" color="primary" />
                </Tooltip>
              )}
            </Typography>
            <Tooltip title={!params.row.contains_pii ? 'Mark as containing PII' : 'Remove mark'}>
              <IconButton color={!params.row.contains_pii ? 'primary' : 'secondary'} onClick={() => flag_pii(params.row.id)}>
                {params.row.contains_pii ? <Flag /> : <FlagOutlined />}
              </IconButton>
            </Tooltip>
            {params.row.contains_pii && (
              <Stack>
                <IconButton
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => openEditRowPII(params.row)}
                  sx={{ float: 'right' }}
                >
                  <EditNote />
                </IconButton>
                <Typography variant="caption">{params.row.form_completion + '/' + FIELDS_FOR_COMPLETE_COUNT.length}</Typography>
              </Stack>
            )}
          </Stack>
        );
      },
    },
    {
      field: 'result',
      headerName: 'Result',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 300,
      flex: 2,
      valueGetter: (value, row) => {
        return row.title;
      },
      renderCell: (params) => {
        return (
          <Typography variant="body2" sx={{ py: 1 }}>
            {params.row.plugin_tag.includes('Google Programmable Search Engine') && (
              <Tooltip title="Google Programmable Search Engine">
                <img alt="logo" src={GPSE_icon} width="16px" height="16px" />
              </Tooltip>
            )}
            {params.row.plugin_tag.includes('Test Plugin') && (
              <Tooltip title="Test Plugin">
                <img alt="logo" src={test_icon} width="16px" height="16px" />
              </Tooltip>
            )}
            {params.row.plugin_tag.includes('Google Search') && (
              <Tooltip title="Google Search Engine">
                <img alt="logo" src={google_icon} width="16px" height="16px" />
              </Tooltip>
            )}
            {params.row.plugin_tag.includes('Yahoo Search') && (
              <Tooltip title="Yahoo Search Engine">
                <img alt="logo" src={yahoo_icon} width="16px" height="16px" />
              </Tooltip>
            )}
            {params.row.plugin_tag.includes('Bing Search') && (
              <Tooltip title="Bing Search Engine">
                <img alt="logo" src={bing_icon} width="16px" height="16px" />
              </Tooltip>
            )}
            <b> {params.row.title} </b>
            <br />
            <Link href={params.row.link} target="_blank">
              {params.row.link}
            </Link>
            <br />
            <span style={{ fontSize: '.825rem', fontWeight: '380' }}>{params.row.snippet}</span>
          </Typography>
        );
      },
    },
    {
      field: 'search_query',
      headerName: 'Query',
      minWidth: 200,
      cellClassName: 'result-snippet',
      hide: 'true',
      renderCell: (params) => {
        return (
          <Typography variant="body1" align="center">
            {array_to_string(params.row.search_query)}
          </Typography>
        );
      },
    },
    {
      field: 'search_meta',
      headerName: 'Search Information',
      minWidth: 200,
      display: 'flex',
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack>
            <Typography variant="body2">
              <b> Templates: </b> {array_to_string(params.row.template_name)}
            </Typography>
            <Typography variant="body2">
              <b> Queries: </b> {array_to_string(params.row.search_query)}
            </Typography>
            <Typography variant="body2">
              <b> Engines: </b> {array_to_string(params.row.plugin_tag)}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'template_name',
      headerName: 'Template',
      minWidth: 125,
      width: 100,
      display: 'flex',
      renderCell: (params) => {
        return (
          <Typography variant="body2" align="center">
            {array_to_string(params.row.template_name)}
          </Typography>
        );
      },
    },
    {
      field: 'contains_pii',
      headerName: 'Contains PII',
      minWidth: 125,
      align: 'center',
      display: 'flex',
      renderCell: (params) => {
        return (
          <div>
            {params.row.contains_pii && (
              <Tooltip title="Result does contains PII" color="success">
                <Check />
              </Tooltip>
            )}
            {!params.row.contains_pii && (
              <Tooltip title="Result does not contain PII" color="secondary">
                <Clear />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      field: 'emphasis',
      headerName: 'Emphasis',
      type: 'string',
      align: 'center',
      display: 'flex',
      renderCell: (params) => {
        return (
          <div>
            {params.row.emphasis && (
              <Tooltip title="Result is found in emphasis list" color="success">
                <Check />
              </Tooltip>
            )}
            {!params.row.emphasis && (
              <Tooltip title="Result is not found in emphasis list" color="secondary">
                <Clear />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      field: 'show',
      headerName: 'Show',
      type: 'bool',
      valueGetter: (_value, row) => {
        var show = true;
        if ((emphasisFilter === 'on' && !row.emphasis) || (emphasisFilter === 'non' && row.emphasis)) show = false;
        if ((flagFilter === 'on' && !row.contains_pii) || (flagFilter === 'non' && row.contains_pii)) show = false;
        return show;
      },
    },
  ];

  const handleRowSelectionChange = (ids) => {
    setSelectedRowIds(ids);
  };

  const deleteSelectedRows = () => {
    setResults(
      results.filter((row) => {
        return !selectedRowIds.includes(row.id);
      })
    );
  };

  const [emphasisFilter, setEmphasisFilter] = useState('off');
  const [flagFilter, setFlagFilter] = useState('off');
  const [filterModel, setFilterModel] = useState({
    items: [],
  });

  useEffect(() => {
    setFilterModel({ items: [{ field: 'show', operator: 'equals', value: 'true' }] });
  }, [emphasisFilter, flagFilter]);

  return (
    <div style={{ width: '100%' }}>
      <Stack direction={'row'} alignItems="center" className="center" sx={{ paddingBottom: '10px' }}>
        <PriorityHighIcon color="primary" />
        <FormControl sx={{ ml: 1, minWidth: 80 }} size="small">
          <InputLabel>Emphasis Filter</InputLabel>
          <Select
            id="emphasis-filter-select"
            autoWidth
            label="Emphasis Filter"
            onChange={(e) => setEmphasisFilter(e.target.value)}
            value={emphasisFilter}
          >
            <MenuItem value={'off'}>Show Any</MenuItem>
            <MenuItem value={'on'}>Emphasized</MenuItem>
            <MenuItem value={'non'}>Not Emphasized</MenuItem>
          </Select>
        </FormControl>
        <Flag sx={{ ml: 4 }} color="error" />
        <FormControl sx={{ ml: 1, minWidth: 80 }} size="small">
          <InputLabel>Flag Filter</InputLabel>
          <Select id="flag-filter-select" autoWidth label="Flag Filter" onChange={(e) => setFlagFilter(e.target.value)} value={flagFilter}>
            <MenuItem value={'off'}>Show Any</MenuItem>
            <MenuItem value={'on'}>Flagged</MenuItem>
            <MenuItem value={'non'}>Not Flagged</MenuItem>
          </Select>
        </FormControl>
        <Tooltip title="Delete Selected">
          <Button sx={{ ml: 3 }} onClick={deleteSelectedRows}>
            <Delete />
          </Button>
        </Tooltip>
      </Stack>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'rank' }],
          },
          columns: {
            columnVisibilityModel: {
              id: false,
              query: false,
              search_query: false,
              contains_pii: false,
              template_name: false,
              emphasis: false,
              show: false,
            },
          },
        }}
        editMode="row"
        disableRowSelectionOnClick
        disableColumnFilter
        getRowHeight={() => 'auto'}
        autoHeight={true}
        rows={results}
        columns={columns}
        checkboxSelection
        rowSelectionModel={selectedRowIds}
        onRowSelectionModelChange={(ids) => handleRowSelectionChange(ids)}
        sx={{
          '& .MuiDataGrid-menuIcon': {
            visibility: 'visible',
            width: '30px',
          },
        }}
        processRowUpdate={(updatedRow, originalRow) => {
          setResults(results.map((row) => (updatedRow.id === row.id ? { ...row, notes: updatedRow.notes } : row)));
          return updatedRow;
        }}
        onProcessRowUpdateError={() => {
          console.log('error');
        }}
        onRowEditStop={(params, event) => {
          if (params.reason !== GridCellEditStopReasons.enterKeyDown) {
            return;
          }
          if (isKeyboardEvent(event) && !event.ctrlKey && !event.metaKey) {
            event.defaultMuiPrevented = true;
          }
        }}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
      />
    </div>
  );
};

export default ResultTable;
